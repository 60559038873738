<template>
	<div class="page_top">
		<div class="page_top_one">
			<div class="logo">
				<div><img src="../assets/top/logo.png" /></div>
			</div>
			<div class="nav">
				<div v-for="(item, index) in pageMenuList"
					:class="(item.children?'page_list':'')+' '+ (item.isCheck?'check_page':'')">
					<router-link v-if="!item.children" :to="{ path: item.listLink }">{{item.name}}</router-link>
					<span v-if="item.children">{{item.name}}</span>
					<div class="horizontal "></div>
					<div v-if="item.children" class="nav_bottom">
						<div class="nav_bottom_list">
							<div v-for="(item1,index) in item.children" :class="(item1.isCheck?'check_page':'')"
								:key="index">
								<router-link :to="{path:item.listLink,query:{companyId:item1.id}}">{{item1.name}}
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="phone">
				<div>
					<div><img src="../assets/top/phone.png" /></div>
					<div class="phone-value">400-800-9519</div>
				</div>
				<div>
					翠鲜缘官方客户服务
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: "top",
		data() {
			return {
				pageMenuList: [{
						name: "首页",
						id: 1,
						listLink: "/",
						pathName: "index",
						isCheck: true
					}, {
						name: "APP下载",
						id: 2,
						listLink: "/Subsidiaries",
						pathName: "Subsidiaries",
						isCheck: true,
					},
					{
						name: "关于我们",
						id: 3,
						listLink: "/SocialServices",
						pathName: "SocialServices",
						isCheck: false
					},
					{
						name: "平台服务",
						id: 4,
						listLink: "/attractInvestment",
						pathName: "attractInvestment",
						isCheck: false
					},
					// {
					// 	name: "新闻资讯",
					// 	id: 5,
					// 	listLink: "/GroupInformation",
					// 	pathName: "GroupInformation",
					// 	isCheck: false
					// },
				],
				btnFlag: false
			};
		},
		created() {},
		mounted() {
			window.addEventListener('scroll', this.scrollToTop)
		},
		computed: {},
		methods: {
		},
		watch: {
			$route: {
				handler(newRoute) {
					let path = newRoute.path;
					let name = newRoute.name;
					let companyId = newRoute.query.companyId;
					this.pageMenuList.findIndex((arr) => {
						if (arr.children != undefined) {
							arr.children.findIndex((arr1) => {
								if (arr1.id == companyId) {
									arr1.isCheck = true
								} else {
									arr1.isCheck = false
								}
							})
						}
						if (arr.listLink === "/"+name) {
							arr.isCheck = true
						} else {
							arr.isCheck = false
						}
						if (name.indexOf(arr.pathName)!=-1) {
							arr.isCheck = true
						} else {
							arr.isCheck = false
						}
					});
				},
				immediate: true,
			},
		},
	};
</script>

<style scoped>
	.contactNumber {
		position: fixed;
		right: 0;
		top: calc(50% - 40px);
		z-index: 100;
		cursor: pointer;
	}

	.contactNumber:hover .contactNumberCont {
		display: block;
	}

	.contactNumber>img {
		width: 80px;
		height: 80px;
	}

	.contactNumberCont {
		box-shadow: 0px 2px 7px 0px #000000;
		display: none;
		position: absolute;
		left: -240px;
		top: 0;
		background: #ffffff;
		width: 200px;
		height: 60px;
		border-radius: 20px;
		padding: 20px;
		text-align: left;
	}

	.contactNumberContTitle {
		height: 18px;
		font-size: 18px;
		font-weight: 600;
		color: #2A2D37;
		line-height: 18px;
		margin-bottom: 10px;
	}

	.contactNumberContTitle1,
	.contactNumberContTitle2 {
		height: 16px;
		font-size: 16px;
		font-weight: 400;
		color: #2A2D37;
		line-height: 16px;
		margin-bottom: 5px;
	}

	.BackToTop {
		position: fixed;
		right: 0;
		top: calc(50% + 100px);
		z-index: 100;
		cursor: pointer;
	}

	.BackToTop>img {
		width: 80px;
		height: 80px;
	}

	.check_page>.horizontal {
		background: #F18E00 !important;
	}

	.horizontal {
		width: 24px;
		height: 2px;
		background: #ffffff;
		margin-top: 8px;
	}

	.nav_bottom {
		display: none;
		background: #ffffff;
		border-top: 1px dotted #efefef;
		padding: 24px 0 27px 0;
		width: 100%;
		position: absolute;
		left: 0;
		top: 68px;
		z-index: 100;
	}

	.page_list:hover .nav_bottom {
		display: block;
	}

	.nav_bottom_list {
		width: 1044px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
	}

	.nav_bottom_list a {
		font-weight: 400;
		text-decoration: none;
		font-size: 16px !important;
		color: #2A2D37;
	}

	.nav_bottom_list a:hover {
		color: #057BE3 !important;
	}

	.nav_bottom_list>div {
		text-align: left;
		margin-bottom: 23px;
	}

	.nav_bottom_list>div:nth-child(4n+1) {
		width: 297px;
	}

	.nav_bottom_list>div:nth-child(4n+2) {
		width: 249px;
	}

	.nav_bottom_list>div:nth-child(4n+3) {
		width: 274px;
	}

	.nav_bottom_list>div:nth-child(4n+4) {
		width: 224px;
	}

	.check_page {
		font-size: 18px;
	}

	.check_page>a {
		color: #F18E00 !important;
		font-weight: 600 !important;
	}

	.check_page span {
		color: #0F86EE !important;
		font-weight: 600 !important;
	}

	.page_top {
		position: relative;
	}

	.page_top_one {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		height: 68px;
	}

	.logo>div {
		margin-top: 2px;
	}

	.logo>div>img {
		width: 115px;
		height: 39px;
	}

	.nav {
		display: flex;
		padding-top: 30px;
	}

	.nav>div:nth-child(1) {
		margin-left: 80px;
		font-weight: 400;
		font-size: 18px;
		color: #2A2D37;
	}

	.nav>div {
		text-align: center;
		margin-right: 40px;
		height: 68px;
		font-size: 18px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.nav>div span {
		font-size: 18px;
		font-weight: 400;
		text-decoration: none;
		color: #000;
		cursor: pointer;
	}

	.nav>div>a {
		font-size: 18px !important;
		font-weight: 400;
		text-decoration: none;
		color: #000;
	}

	.nav>div:last-child {
		margin-right: 213px;
	}
	.phone>div:nth-child(2) {
		height: 12px;
		font-weight: 400;
		font-size: 12px;
		color: #B8BFCD;
		text-align: right;
		line-height: 12px;
		margin-top: 10px;
	}
	.phone>div:nth-child(1) img{
		width: 14px;
		height: 14px;
	}
	.phone>div:nth-child(1) {
		display: flex;
		height: 18px;
		font-weight: bold;
		font-size: 18px;
		color: #000000;
		line-height: 18px;
	}
	.phone-value{
		font-size: 20px;
	}
	.phone>div:nth-child(1)>div:nth-child(1) {
		margin-right: 6px;
	}
</style>
