<template>
	<div>
		<publicTop ></publicTop>
		<router-view/>
		<publicBottom ></publicBottom>
	</div>
</template>

<script>
import publicTop from "./publicTop";
import publicBottom from "./publicBottom";
export default {
  name: "index",
  components: { 
	  publicTop,
	  publicBottom
  },
};
</script>

<style>
</style>