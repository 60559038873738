import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "../components/Layout.vue";

Vue.use(VueRouter)

const routes = [{
		path: '/',
		component: Layout,
		children: [{ //首页
				path: '/',
				name: 'index',
				meta: {
					title: "翠鲜缘-北京水果批发网,水果蔬菜批发配送平台"
				},
				component: () => import('../views/index/index.vue')
			},
			{ //旗下公司
				path: '/Subsidiaries',
				name: 'Subsidiaries',
				meta: {
					title: "翠鲜缘-旗下公司"
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('../views/Subsidiaries/Subsidiaries.vue')
			},
			{ //社会化服务
				path: '/SocialServices',
				name: 'SocialServices',
				meta: {
					title: "翠鲜缘-社会化服务"
				},
				component: () => import('../views/SocialServices/SocialServices.vue')
			},
			{ //招商合作
				path: '/attractInvestment',
				name: 'attractInvestment',
				meta: {
					title: "翠鲜缘-招商合作"
				},
				component: () => import('../views/attractInvestment/attractInvestment.vue')
			},
			{ //集团资讯
				path: '/GroupInformation',
				name: 'GroupInformation',
				meta: {
					title: "翠鲜缘-新闻资讯"
				},
				component: () => import('../views/GroupInformation/GroupInformation.vue'),
			},
			{ //集团资讯详情
				path: '/GroupInformationDetails',
				name: 'GroupInformationDetails',
				meta: {
					title: "翠鲜缘-新闻资讯"
				},
				component: () => import('../views/GroupInformation/GroupInformationDetails.vue')
			}

		]
	}

]

const router = new VueRouter({
	routes
})
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	document.documentElement.scrollTop = 0
	next()
})

export default router
